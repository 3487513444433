const AppAboutMe = () => {
    return (
        <div className="aboutme section">
            <div className="page-title">
                <h2>Обо
                    <span> Мне</span>
                </h2>
            </div>
            <div className="section-content">
                <div className="row">
                    <div className="col-md-8 col-lg-6 col-xl-7 col-xxl-8">
                        <p>Приветствую на моей странице!<br/>
                            Меня зовут Алексей.<br/>
                            В данный момент я ищу работу DevOps инженера. Рассматриваю предложения удалённой работы, а также предложения с релокацией.
                            Я имею высшее образование и знания в области программирования на языках Python, JavaScript и HTML. У меня есть опыт в использовании фреймворков React и Django для создания веб-приложений. Применяю Docker для контейнеризации и разработки. Я имею опыт работы над проектами, разработкой баз данных и анализом данных. Есть опыт написания и ведения документации, а также составления обучающих инструкций. Я готов обучаться и получать новые навыки, изучать новые технологии и использовать их для решения проблем.
                            Последние годы работал в крупных нефте/газо добывающих компаниях. В основном в небольших командах.</p>
                    </div>
                    <div className="col-md-4 col-lg-6 col-xl-5 col-xxl-4">
                        <div className="info-list">
                            <ul>
                                <li>
                                    <span className="title">Локация</span>
                                    <span className="value">Россия</span>
                                </li>
                                <li>
                                    <span className="title">Адрес</span>
                                    <span className="value">Санкт-Петербург, Петергоф.</span>
                                </li>
                                <li>
                                    <span className="title">e-mail</span>
                                    <span className="value">leventcov.a.v@gmail.com</span>
                                </li>
                                <li>
                                    <span className="title">Phone RU</span>
                                    <span className="value">+7 929 258 0954</span>
                                </li>
                                <li>
                                    <span className="title">Phone TR</span>
                                    <span className="value">+90 505 915 1924</span>
                                </li>
                                <li>
                                    <span className="title">Telegram</span>
                                    <span className="value"><a href={"https://t.me/L_Alexeyka"} target="_blank">L_Alexeyka</a> </span>
                                </li>
                                <li>
                                    <span className="title">VK</span>
                                    <span className="value"><a href={"https://vk.com/l_alexeyka"} target="_blank">L_Alexeyka</a> </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="white-space-50"></div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <div className="block-title">
                            <h3>Чем <span>Я Занимаюсь</span></h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6  col-lg-6">
                        <div className="col-inner">
                            <div className="info-list-w-icon">
                                <div className="info-block-w-icon">
                                    <div className="ci-icon">
                                        <i className="fa-sharp fa-solid fa-server"></i>
                                    </div>
                                    <div className="ci-text">
                                        <h4>Администрирование</h4>
                                        <p>Более 10 лет системного администрирования Windows server (2003-2019)  и Linux систем(CentOS, Astra, Fedora, Ubuntu, Debian). Аппаратная виртуализация на базе vmware ESXi(6.0 – 6.5), oVirt, Proxmox, vmware workstation, oracle VirtualBox, Linux KVM . Контейнерная виртуализация Docker, Docker-Compose.  Есть опыт смены платформы виртуализации с vmware на oVirt в масштабах предприятия (запускал пилотный проект и мигрировал несколько кластеров)</p>
                                    </div>
                                </div>
                                <div className="info-block-w-icon">
                                    <div className="ci-icon">
                                        <i className="fa-solid fa-gears"></i>
                                    </div>
                                    <div className="ci-text">
                                        <h4>Python backend разработка</h4>
                                        <p>Год опыта разработки на Python 3, в сфере анализа безопасности информационных систем и обработки больших данных. Есть опыт написания telegram ботов. Понимание принципов ООП. Основы тестирования api средствами pytest. Есть опыт контейнерной разработки и отладки приложений используя Pycharm.</p>
                                    </div>
                                </div>
                                <div className="info-block-w-icon">
                                    <div className="ci-icon">
                                        <i className="fa fa-cloud"></i>
                                    </div>
                                    <div className="ci-text">
                                        <h4>DevOps</h4>
                                        <p>Я Junior DevOps-инженер, использующий Docker, Docker Compose, Ansible и Jenkins для автоматизации рутинных задач и обеспечения процесса разработки и доставки приложений. Я имею опыт работы с YC для автоматизации развертывания и конфигурации инфраструктуры.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6  col-lg-6">
                        <div className="col-inner">
                            <div className="info-list-w-icon">
                                <div className="info-block-w-icon">
                                    <div className="ci-icon">
                                        <i className="fa-solid fa-database"></i>
                                    </div>
                                    <div className="ci-text">
                                        <h4>Database</h4>
                                        <p>Есть опыт работы с базами данных такими как Postgress, Oracle и MS SQL, и опыт работы с ними Средствами Python SQLAlchemy.  Я также могу использовать эти технологии для создания приложений и веб-сайтов, которые используют базы данных для хранения данных. Также я могу использовать свои навыки для написания запросов SQL, чтобы извлечь данные из базы данных и использовать их для анализа и визуализации.</p>
                                    </div>
                                </div>
                                <div className="info-block-w-icon">
                                    <div className="ci-icon">
                                        <i className="fa-solid fa-code"></i>
                                    </div>
                                    <div className="ci-text">
                                        <h4>Web разработка</h4>
                                        <p>Я начинающий веб-разработчик, использующий HTML, CSS, JavaScript и React JS. Я имею опыт работы с этими технологиями, включая создание интерактивных веб-сайтов, отзывчивого дизайна, а также приложений для мобильных устройств. Данный сайт написан с использованием библиотеки React.js. Есть опыт работы с движком WordPress.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="white-space-10"></div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <div className="block-title">
                            <h3>Мои хобби:</h3>
                        </div>
                    </div>
                    <div className="col-12">
                        <p>В свобоое от работы и учебы время люблю поиграть в видеоигры, пешие походы, путешествия и велопрогулки. Очень нравится pepakura и сборка моделей из бумаги, хотя времени занимает изрядно :)</p>
                    </div>
                </div>
                <div className="white-space-50"></div>
            </div>

        </div>
    )
}
export default AppAboutMe;
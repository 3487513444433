const AppResume = () => {
    return (
        <div className="resume section">
            <div className="page-title">
                <h2>Резюме</h2>
            </div>
            <div className="section-content">
                <div className="row">
                    <div className="col-12 col-sm-7 col-md-7">
                        <div className="block-title">
                            <h3>Образование</h3>
                        </div>
                        <div className="timeline timeline-second-style clearfix">
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">2010</h5>
                                    <span className="item-company">СибАДИ (Омск)</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Специалист по защите информации</h4>
                                    <p>Получил высшее образование по специальности "Комплексное обеспечение
                                        информационной безопасноти Автоматизированных систем" кафедры Информационной
                                        безопасности, Сибирского автомобильно-дорожного института г. Омска</p>
                                </div>
                            </div>

                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">2005</h5>
                                    <span className="item-company">СРШБ(колледж)</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Техник программист</h4>
                                    <p>Получил среднее специальное образование по специальности "Программное обеспечение
                                        вычислительной техники и автоматизированных систем", Сибирской Региональной
                                        Школы Бизнеса.</p>
                                </div>
                            </div>
                        </div>
                        <div className="white-space-50"></div>
                        <div className="block-title">
                            <h3>Опыт работы</h3>
                        </div>
                        <div className="timeline timeline-second-style clearfix">
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">Май 2023 — Настоящее время</h5>
                                    <span className="item-company">ПАО Банк Синара</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Системный инженер</h4>
                                    <p>Первый релевантный опыт DevOps! Проект: Создание корпоративного хранилища данных (КХД) на базе Arena Data (Greenplum)
                                    Несколько различных команд разработки, координирование работы, устарнение ошибок и разрешение проблем команд с доставкой кода на различные среды.</p>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">Ноябрь 2022 — Май 2023</h5>
                                    <span className="item-company">Freelance</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Freelance</h4>
                                    <p>Отдыхал, учился и развивал свои навыки и образование с
                                        помощью онлайн-курсов и самообразования. Я постоянно развиваю свои знания, а
                                        также ищу новые возможности для роста. Применяю полученный опыт для
                                        решения реальных задач и разработки продуктов, которые помогут достичь
                                        поставленных целей.</p>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">Январь 2022 — Ноябрь 2022</h5>
                                    <span className="item-company">Газпромнефть-ЦР</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Программист Python</h4>
                                    <p>Лучшее место работы! Дало мне силы средства и главное толчок к саморазвитию!
                                        Открыло путь в разработку и интеграцию. Я принимал участие в проекте
                                        автоматического анализа событий ИБ, разработал и интегрировал один из модулей. В
                                        основном, я применял знания и навыки в разработке приложений с помощью
                                        технологий, таких как Python, Django, Django ORM, Docker а так же базы
                                        данных. </p>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">Декабрь 2018 — Январь 2022</h5>
                                    <span className="item-company">Газпром добыча Уренгой</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Системный администратор</h4>
                                    <p>Отличная организация! Тут я углубился в аппаратную виртуализацию на базе ESXi,
                                        под нашим контролем было два ЦОДа а также более двух десятков
                                        программно-аппаратных комплексов по всему городу. Углубил познания в ОС Linux и
                                        разворачивал пилотный проект по виртуализации на базе oVirt virtualization.</p>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">Октябрь 2014 — Декабрь 2018</h5>
                                    <span className="item-company">"Газпромбурение" филиал "Уренгой бурение"</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Системный администратор</h4>
                                    <p>Первая работа на холодном севере! Получил прекрасный опыт в администрировании AD
                                        и домена, почтового сервера Lotus Notes. Первый опыт большой виртуализации ESXi.
                                        Частенько автоматизировал рутинные задачки.</p>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period">Июль 2010 — Апрель 2014</h5>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Отделение пенсионного фонда России по Омской области</h4>
                                    <br/>
                                    <h4 className="item-title">Удостоверяющий центр "БИТ"</h4><br/>
                                    <h4 className="item-title">ЗАО "Информатика Сибири"</h4><br/>
                                    <p>Первые три места работы! Подробности в резюме!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5 col-md-5">
                        <div className="block-title">
                            <h3>Стек</h3>
                        </div>
                        <ul className="knowledges">
                            <li>Python 3.9+</li>
                            <li>JavaScript</li>
                            <li>HTML/CSS</li>
                            <li>Django</li>
                            <li>Docker</li>
                            <li>Docker-compose</li>
                            <li>Ansible</li>
                            <li>Postgres SQL</li>
                            <li>Oracle Database 9i, 11g</li>
                            <li>MS SQL Server</li>
                            <li>IDE Pycharm</li>
                            <li>IDE WebStorm</li>
                            <li>ssh</li>
                            <li>Windows Server 2003-2019</li>
                            <li>CentOS Linux</li>
                            <li>Astra Linux</li>
                            <li>Fedora</li>
                            <li>Ubuntu</li>
                            <li>Debian</li>
                            <li>vmware Esxi</li>
                            <li>vmware Workstation</li>
                            <li>oVirt</li>
                            <li>zVirt</li>
                            <li>Oracle VirtualBox</li>
                            <li>Linux KVM</li>
                            <li>Proxmox Virtual Environment</li>
                            <li>Jira</li>
                            <li>Service Desk</li>
                            <li>Putty</li>
                            <li>MobaXterm</li>
                            <li>winscp</li>
                            <li>React.js</li>
                            <li>Jenkins CI/CD</li>
                            <li>Oracle PL/SQL Developer</li>
                            <li>Communication</li>
                            <li>OpenVPN</li>
                            <li>Postman</li>
                            <li>Git</li>
                        </ul>
                        <div className="white-space-10"></div>
                        <div className="timeline timeline-second-style clearfix">
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2023 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Автоматизация тестирования+Программирование на Python с нуля</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2023 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Полный курс по JavaScript + React - с нуля до результата</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2023 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Jenkins для DevOps Инженеров</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2023 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Jenkins - шаг за шагом</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2023 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Kubernetes Foundations (RU)</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2023 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Kubernetes с Нуля для DevOps Инженеров</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2022 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Python + Django: с нуля до коммерческих приложений</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2022 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Django для Начинающих</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2022 год</h5>
                                    <span className="item-company">Udemy</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Полное руководство по Python 3: от новичка до специалиста</h4>
                                </div>
                            </div>
                            <div className="timeline-item clearfix">
                                <div className="left-part">
                                    <h5 className="item-period"> 2021 год</h5>
                                    <span className="item-company">УЦ Микротест</span>
                                </div>
                                <div className="divider"></div>
                                <div className="right-part">
                                    <h4 className="item-title">Implementing and Administering Cisco Solutions(CCNA)</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="white-space-50"></div>
            </div>
        </div>
    )
}
export default AppResume;


import {Formik, Form, Field, ErrorMessage, useField} from "formik";
import * as Yup from 'yup'
import ReCAPTCHA from "react-google-recaptcha";
import {useRef, useState} from "react";

const url = "https://l-alex.ru/api/send-message/"
// const url = "http://127.0.0.1:8000/api/send-message/"

const MyTextInput = ({label, ...props}) => {
    const [field, meta] = useField(props);
    let danger = ""
    if (meta.touched && meta.error) {
        danger = "test222"
    }
    return (
        <>
            <label htmlFor={props.name} className="formlabel">{label}</label>
            <input {...props} {...field} className={"111" + "222" + danger}/>
            <div className="help-block with-errors">{meta.error}</div>
        </>
    )
}


const SendMessageForm = () => {
    const capture = useRef()
    const success = useRef()
    const [capt, setCapt] = useState()
    // const [capt, setCapt] = useState(true)

    const onChange = (value) => {
        setCapt(value)
    }
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .required('Обязательное поле!'),
                email: Yup.string()
                    .email('Неправильный email'),
                phone: Yup.string(),
                subject: Yup.string(),
                message: Yup.string()
            })}
            onSubmit={(values, {resetForm}) => {
                console.log(values)
                if (capt) {
                    fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values, null, 2)
                    })
                    capture.current.classList.remove("danger-captcha")
                    success.current.classList.add("fine")
                    resetForm()
                } else capture.current.classList.add("danger-captcha")
            }}>

            <Form className="contact-form">
                <div className="controls two-columns">
                    <div className="fields clearfix">
                        <div className="left-column">
                            <MyTextInput
                                label="Ваше имя"
                                id="name"
                                name="name"
                                type="text"
                            />
                            <MyTextInput
                                label="Ваш email"
                                id="email"
                                name="email"
                                type="text"
                            />
                            <MyTextInput
                                label="Ваш номер телефона"
                                id="phone"
                                name="phone"
                                type="text"
                            />
                            <MyTextInput
                                label="Тема сообщения"
                                id="subject"
                                name="subject"
                                type="text"
                            />
                        </div>
                        <div className="right-column">
                            <div className="form-group form-group-with-icon">
                                <label htmlFor="text">Ваше сообщение</label>
                                <Field
                                    id="form_message"
                                    name="message"
                                    as="textarea"
                                    className="form-control form-control-text"
                                />
                                <ErrorMessage className='error' name="textMessage" component="div"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="futer-form">
                    <ReCAPTCHA
                        sitekey="6LeOUU0kAAAAAOZUqZdHP3hcr27N4sMPFiSfWsrb"
                        onChange={onChange}
                        className="recaptcha"
                    />
                    <div className="captcha" ref={capture}>Пройдите проверку пожалуйста</div>
                    <button className="btn btn-send" type="submit">Отправить</button>
                    <div className="send-successful" ref={success}>Сообщение успешно отправлено!</div>
                </div>
            </Form>
        </Formik>
    )
}


const AppContact = () => {

    return (
        <div className="contact section">
            <div className="page-title">
                <h2>Контакты</h2>
            </div>
            <div className="section-content">
                <div className="row">
                    <div className="col-xs-12 col-sm-4">
                        <div className="lm-info-block gray-default">
                            <i className="lnr lnr-map-marker"></i>
                            <h4>Antalya</h4>
                            <span className="lm-info-block-value"></span>
                            <span className="lm-info-block-text"></span>
                        </div>

                        <div className="lm-info-block gray-default">
                            <i className="lnr lnr-phone-handset"></i>
                            <h4>+7 929 258 0954</h4>
                            <h4>+90 505 915 1927</h4>
                            <span className="lm-info-block-value"></span>
                            <span className="lm-info-block-text"></span>
                        </div>

                        <div className="lm-info-block gray-default">
                            <i className="lnr lnr-envelope"></i>
                            <h4>leventcov.a.v@gmail.com</h4>
                            <span className="lm-info-block-value"></span>
                            <span className="lm-info-block-text"></span>
                        </div>

                        <div className="lm-info-block gray-default">
                            <i className="fa-brands fa-telegram"></i>
                            <h4><a className="contact-link" href={"https://t.me/L_Alexeyka"}>L_Alexeyka</a></h4>
                            <span className="lm-info-block-value"></span>
                            <span className="lm-info-block-text"></span>
                        </div>


                    </div>
                    <div className="col-xs-12 col-sm-8">
                        <div id="map" className="map">
                            <div className="lmpixels-map">
				<iframe 
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d76088.82365200872!2d30.26686339256447!3d59.92445753113378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4696378cc74a65ed%3A0x6dc7673fab848eff!2z0KHQsNC90LrRgi3Qn9C10YLQtdGA0LHRg9GA0LMsINCg0L7RgdGB0LjRjw!5e0!3m2!1sru!2str!4v1711793401756!5m2!1sru!2str" 
					width="100%" height="100%"
				></iframe>
                            </div>
                        </div>
                        <div className="block-title send-message-title">
                            <h3>Чем я могу <span> Помочь Вам?</span></h3>
                        </div>
                        <SendMessageForm/>
                    </div>
                </div>
                <div className="white-space-20"></div>
            </div>
        </div>
    )
}
export default AppContact
import AppHeader from "../appHeader/AppHeader";
import AppNavigation from "../appNavigation/AppNavigation";
import AppHome from "../appHome/AppHome";
import {useState, forwardRef, useRef, useEffect} from "react";
import AppAboutMe from "../appAboutMe/AppAboutMe";
import FlipMove from 'react-flip-move';
import AppResume from "../appResume/AppResume";
import AppPortfolio from "../appPortfolio/AppPortfolio";
import AppContact from "../appContact/AppContact";

function App() {
    const listContent=["home","about-me","resume","portfolio","contact"]
    const [activeItem, setActiveItem] = useState("home")
    const [activeMenu, setActiveMenu] = useState(false)
    const hamburger = useRef()

    const setNextContent=()=>{
        listContent.forEach((item,i)=>{
            if (item===activeItem){
                i===listContent.length-1 ? setActiveItem(listContent[0]):setActiveItem(listContent[++i])
            }
        })
        if (activeMenu) toggleActiveMenu()
    }

    const setPrevContent=()=>{
        listContent.forEach((item,i)=>{
            if (item===activeItem){
                i===0 ? setActiveItem(listContent[listContent.length-1]):setActiveItem(listContent[--i])
            }
        })
        if (activeMenu) toggleActiveMenu()
    }

    const toggleActiveMenu = () => {
        setActiveMenu(!activeMenu)
    }
    useEffect(() => {
        activeMenu ? hamburger.current.classList.add('hamburger_active') : hamburger.current.classList.remove('hamburger_active')

    }, [activeMenu])

    const customEnterAnimation = {
        from: {transform: 'scale(0.1,0.1)'},
        to: {transform: 'scale(1,1)'}
    }
    const customLeaveAnimation = {
        from: {transform: 'scale(1,1)'},
        to: {transform: 'scale(0.1,1)'}
    }
    let content
    switch (activeItem) {
        case "home": {
            content = {key: 1, comp: <AppHome/>}
            break;
        }
        case "about-me": {
            content = {key: 2, comp: <AppAboutMe/>}
            break;
        }
        case "resume": {
            content = {key: 3, comp: <AppResume/>}
            break;
        }
        case "portfolio": {
            content = {key: 4, comp: <AppPortfolio/>}
            break;
        }
        case "contact": {
            content = {key: 5, comp: <AppContact/>}
            break;
        }
        default:
            content = null
    }

    const FunctionalArticle = forwardRef((props, ref) => {
        return (
            <div ref={ref} className="content">
                {props.comp}
            </div>)
    });

    const disableMenu = () => {
      if(activeMenu) setActiveMenu(false)
    }

    return (
        <div className="container">
            <div className="hamburger" ref={hamburger} onClick={() => toggleActiveMenu()}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="arrow-navitation arrow-forward"  onClick={setNextContent}>
                <div className="forward"><i className="lnr lnr-chevron-right"></i></div>
            </div>
            <div className="arrow-navitation arrow-backward" onClick={setPrevContent}>
                <div className="backward"><i className="lnr lnr-chevron-left" ></i></div>
            </div>
            <div className="row g-0">
                <div className="col-3">
                    <AppHeader setActiveItem={setActiveItem} isActive={activeMenu} test={toggleActiveMenu}/>
                </div>
                <div className="col-8" onClick={disableMenu}>
                    <FlipMove enterAnimation={customEnterAnimation} leaveAnimation={customLeaveAnimation}
                              className={'content'}>
                        <FunctionalArticle key={content.key} {...content}/>
                    </FlipMove>
                </div>
                <div className="col-1">
                    <AppNavigation setActiveItem={setActiveItem}/>
                </div>
            </div>
        </div>
    );
}

export default App;

import sahibinden from "../../resources/img/sahibinden.png"

const AppPortfolio = () => {
    return (
        <div className="portfolio section">
            <div className="page-title">
                <h2>Портфолио</h2>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <img
                            src={"https://habrastorage.org/r/w1560/files/df0/491/ba4/df0491ba483b4446bc9261be83f69ebd.png"}
                            className="card-img-top" alt="logo"/>
                        <div className="divider"/>
                        <div className="card-body">
                            <h5 className="card-title">Habr статья</h5>
                            <p className="card-text">React+Django как написать Hello World</p>
                            <a href="https://habr.com/ru/post/713490/" target="_blank" className="btn btn-primary"
                               rel="noreferrer">habr.com</a>
                        </div>
                    </div>
                    <div className="card">
                        <img
                            src={"https://habrastorage.org/r/w1560/files/df0/491/ba4/df0491ba483b4446bc9261be83f69ebd.png"}
                            className="card-img-top" alt="logo"/>
                        <div className="divider"/>
                        <div className="card-body">
                            <h5 className="card-title">Habr статья</h5>
                            <p className="card-text" style={{ fontSize: '14px' }}>Автоматизация развертывания стенда Kubernetes</p>
                            <a href="https://habr.com/ru/articles/751582/" target="_blank" className="btn btn-primary"
                               rel="noreferrer">habr.com</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="white-space-50"></div>
        </div>
    )
}

export default AppPortfolio
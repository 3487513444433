import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const AppHome = (props) => {
    return (
        <div className="home">
                <div className="title">
                    <div className="ru"><h2>Левенцов Алексей</h2></div>
                    <div className="en"><h2>Leventsov Aleksei</h2></div>
                </div>
                <div className="carousel">
                    <CarouselProvider
                        naturalSlideWidth={10}
                        naturalSlideHeight={10}
                        totalSlides={3}
                        interval={5000}
                        isPlaying={true}
                    >
                        <Slider>
                            <Slide index={0}>Python Developer</Slide>
                            {/*<Slide index={1}>Web Developer</Slide>*/}
                            <Slide index={2}>DevOps</Slide>
                        </Slider>
                    </CarouselProvider>
                </div>
        </div>
    )
}
export default AppHome;
const AppNavigation = (props) => {

    const changeActiveItem = (newActive) => {
        const items = document.querySelectorAll('.menu-item')
        items.forEach(item => {
            item.classList.remove("active")
            if (newActive === item.getAttribute("data-id")) {
                item.classList.add("active")
                props.setActiveItem(newActive)
            }
        })
    }

    const toggleContent = (e) => {
        changeActiveItem(e.target.parentNode.parentNode.getAttribute("data-id"))
    }
    return (
        <div className="navigation">
            <ul className="main-menu" onClick={toggleContent}>
                <li className="menu-item" data-id={"home"}>
                    <a href="#" className="nav-anim">
                        <span className="menu-icon lnr lnr-home"></span>
                        <span className="link-text">Домашняя</span>
                    </a>
                </li>
                <li className="menu-item" data-id={"about-me"}>
                    <a href="#" className="nav-anim">
                        <span className="menu-icon lnr lnr-user"></span>
                        <span className="link-text">Обо мне</span>
                    </a>
                </li>
                <li className="menu-item" data-id={"resume"}>
                    <a href="#" className="nav-anim">
                        <span className="menu-icon lnr lnr-graduation-hat"></span>
                        <span className="link-text">Резюме</span>
                    </a>
                </li>
                <li className="menu-item" data-id={"portfolio"}>
                    <a href="#" className="nav-anim">
                        <span className="menu-icon lnr lnr-briefcase"></span>
                        <span className="link-text">Портфолио</span>
                    </a>
                </li>
                {/*<li className="menu-item" data-id={"blog"}>*/}
                {/*    <a href="#blog" className="nav-anim">*/}
                {/*        <span className="menu-icon lnr lnr-book"></span>*/}
                {/*        <span className="link-text">Blog</span>*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className="menu-item" data-id={"contact"}>
                    <a href="#" className="nav-anim">
                        <span className="menu-icon lnr lnr-envelope"></span>
                        <span className="link-text">Контакты</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}
export default AppNavigation;
import photo from '../../resources/img/photo.webp'
import logo from '../../resources/img/logo/logo.webp'
import cv from '../../resources/files/CV_Leventsov.pdf'
import { useRef} from "react";

const AppHeader = (props) => {
    const blockHeader = useRef()

    const changeActiveItem = (newActive) => {
        const items = document.querySelectorAll('.menu-item')
        items.forEach(item => {
            item.classList.remove("active")
            if (newActive === item.getAttribute("data-id")) {
                item.classList.add("active")
                props.setActiveItem(newActive)
            }
        })
        console.log('op')
        props.test()
    }
    const toggleContent = (e) => {
        changeActiveItem(e.target.parentNode.parentNode.getAttribute("data-id"))
    }

    return (
        <header id="site_header" className={props.isActive===true? 'header active':"header"} ref={blockHeader}>
            <div className="header-content">
                <div className="header-photo"><img src={photo} alt='my_photo'/></div>
                <div className="header-titles">
                    <h2>Leventsov Aleksei</h2>
                    <h4>Python developer</h4>
                </div>
            </div>
            <div className="header-navigation">
                <ul className="header-main-menu" onClick={toggleContent}>
                    <li className="menu-item" data-id={"home"}>
                        <a href="#" className="nav-anim">
                            <span className="link-text">Домашняя</span>
                        </a>
                    </li>
                    <li className="menu-item" data-id={"about-me"}>
                        <a href="#" className="nav-anim">
                            <span className="link-text">Обо мне</span>
                        </a>
                    </li>
                    <li className="menu-item" data-id={"resume"}>
                        <a href="#" className="nav-anim">
                            <span className="link-text">Резюме</span>
                        </a>
                    </li>
                    <li className="menu-item" data-id={"portfolio"}>
                        <a href="#" className="nav-anim">
                            <span className="link-text">Портфолио</span>
                        </a>
                    </li>
                    {/*<li className="menu-item" data-id={"blog"}>*/}
                    {/*    <a href="#blog" className="nav-anim">*/}
                    {/*        <span className="link-text">Blog</span>*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    <li className="menu-item" data-id={"contact"}>
                        <a href="#" className="nav-anim">
                            <span className="link-text">Контакты</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="social-links">
                <ul>
                    <li><a href="https://www.linkedin.com/in/aleksei-leventsov/" target="_blank" rel="noreferrer"><i
                        className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="https://www.facebook.com/profile.php?id=100086263517605" target="_blank" rel="noreferrer"><i
                        className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://vk.com/l_alexeyka" target="_blank" rel="noreferrer"><i className="fab fa-vk"></i></a></li>
                </ul>
            </div>
            <div className="header-buttons">
                <a href={cv}
                   target="_blank" className="btn btn-primary"  rel="noreferrer">Download CV</a>
            </div>
            <div className="white-space-50"></div>
            <div className="header-logo">
                <img src={logo}/>
            </div>
        </header>
    )
}
export default AppHeader;